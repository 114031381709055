import {Prompt} from '~/components/prompt';
import {ArrowRightEndOnRectangleIcon} from '@heroicons/react/16/solid';
import {Button} from '~/components/ui/button';
import {Link} from 'react-router';
import {UserPlusIcon} from '@heroicons/react/20/solid';

export default function Login() {
  return (
    <>
      <h1 className="mt-12 text-center text-3xl">Login</h1>

      <div className="flex flex-wrap justify-center gap-3">
        <Button variant="outline" asChild>
          <Link
            to={{
              pathname: '/kinde-auth/register',
              search: '?org_code=org_ad433b348a6e',
            }}
          >
            <UserPlusIcon className="size-5" />
            <span>Sign up</span>
          </Link>
        </Button>
        <Button asChild>
          <Link
            to={{
              pathname: '/kinde-auth/login',
              search: '?org_code=org_ad433b348a6e',
            }}
          >
            <ArrowRightEndOnRectangleIcon className="size-5" />
            <span>Login</span>
          </Link>
        </Button>
      </div>

      <Prompt />
    </>
  );
}
